import { useStaticQuery } from 'gatsby';
import React from 'react';
import { navigate } from 'gatsby-plugin-intl';
import { Content } from '@/common/components/content';
import { Layout } from '@/common/components/layout';
import { SEO } from '@/common/components/seo';
import { useUri } from '@/common/hooks';
import { mapMaybe } from '@/common/utils/map-maybe';
import { jsx as ___EmotionJSX } from "@emotion/core";
var query = "2801492362";

var MaintenancePage = function MaintenancePage() {
  var _data$cms, _data$cms$maintenance, _data$cms$maintenance2;

  var data = useStaticQuery(query);
  var attributes = data === null || data === void 0 ? void 0 : (_data$cms = data.cms) === null || _data$cms === void 0 ? void 0 : (_data$cms$maintenance = _data$cms.maintenancePage) === null || _data$cms$maintenance === void 0 ? void 0 : (_data$cms$maintenance2 = _data$cms$maintenance.data) === null || _data$cms$maintenance2 === void 0 ? void 0 : _data$cms$maintenance2.attributes;
  var pageContent = attributes === null || attributes === void 0 ? void 0 : attributes.content;
  var pageTitle = attributes === null || attributes === void 0 ? void 0 : attributes.title;

  var _useUri = useUri(),
      uriHelper = _useUri.uriHelper;

  if (!CONFIG.maintenanceMode) {
    navigate(uriHelper.getHomeUrl());
    return null;
  }

  return ___EmotionJSX(Layout, {
    basicMode: true
  }, ___EmotionJSX(SEO, {
    title: pageTitle !== null && pageTitle !== void 0 ? pageTitle : '',
    meta: [{
      name: 'robots',
      content: 'noindex'
    }]
  }), pageContent ? ___EmotionJSX(Content, {
    contentBlocks: mapMaybe(pageContent)
  }) : null);
};

MaintenancePage.displayName = "MaintenancePage";
export { MaintenancePage as default };